import { CgInsights } from "react-icons/cg"

export const aboutUs = {
    executiveSumary: {
        title: "Executive Summary",
        content1: "The hazardous materials transportation industry relies on a complex network of catalysts (the carriers who move hazmat shipments), suppliers, dispatchers, brokers, and pilot drivers. Inefficiencies, communication breakdowns, and lack of transparency cause headaches for all parties. EusoTrip revolutionizes hazardous material logistics by providing a technology-first platform that streamlines operations, increases transparency, and ensures safety for everyone involved.",
        content2: "Like UberFreight revolutionized freight for general cargo, EusoTrip transforms hazmat logistics by offering on-demand carrier matching, transparent pricing, and real-time tracking."
    },
    stakeholders: {
        title: "Stakeholders",
        content1: "EusoTrip isn't just about individual efficiencies; it's about creating a connected hazmat logistics ecosystem. Suppliers find the right carriers, dispatchers optimize their operations, and brokers expand their reach – all in one secure platform.",
        content2: "The global HazMat logistics market, estimated at $230.2 billion in 2022, offers immense potential. EusoTrip's focus on technology-enabled optimization, compliance, and collaboration positions us to capture significant market share. Our team's deep industry expertise, initial traction with $600K in seed funding, a $200K Department of Transportation innovation grant, and over 2,000 potential users on our waitlist demonstrate our potential. EusoTrip is poised to transform the HazMat logistics landscape, unlocking new levels of efficiency, safety, and profitability for both investors and businesses"
    },
    introduction: {
        title: "Introduction",
        content1: "The transportation of hazardous materials (HazMat) is a critical component of global supply chains, valued at approximately $230.2 billion in 2022. This market is projected to grow significantly, reaching $311.27 billion by 2028 at a compound annual growth rate (CAGR) of 7.4​ (ResearchHub)​​ (BlueWeave Consulting)​. Despite its importance, the HazMat logistics industry faces numerous challenges, including inefficiencies, opaque pricing, and complex compliance requirements. These issues lead to delays, cost overruns, and safety risks, affecting multiple industries.",
        content2: "EusoTrip aims to transform the HazMat logistics landscape by offering a technology-driven solution that prioritizes safety, efficiency, and transparency for all stakeholders. Our platform directly addresses key pain points in the HazMat trucking sector, including extended wait times, restricted routes, specialized training costs, and the risk of costly incidents.",
        imageBlock: {
            src: "./introduction.png",
            alt: "./introduction.png"
        }
    },
    marketProblems: {
        title: "Market Problems",
        imageBlock1: [
            {
                src: "./market-problems-image-1.png",
                alt: "./market-problems-image-1.png"
            },
            {
                src: "./market-problems-image-2.png",
                alt: "./market-problems-image-2.png"
            },
            {
                src: "./market-problems-image-3.png",
                alt: "./market-problems-image-3.png"
            }
        ],
        imageBlock2: [
            {
                src: "./market-problems-image-4.png",
                alt: "./market-problems-image-4.png"
            },
            {
                src: "./market-problems-image-5.png",
                alt: "./market-problems-image-5.png"
            },
            {
                src: "./market-problems-image-6.png",
                alt: "./market-problems-image-6.png"
            }
        ]
    },
    whatWeHave: {
        title: "What We Have?",
        content2: "The EusoTrip Solution: Revolutionizing HazMat Logistics",
        imageBlock: [
            {
                imageSrc: './wwh-group-1-image-1.png',
                imageAlt: 'Image 1',
                iconSrc: './wwh-group-1-icon-1.svg',
                iconAlt: 'Icon-1',
                title: 'Advanced real-time tracking',
                description: 'Monitor your shipments in real-time with geo-fencing and predictive ETAs, ensuring unparalleled safety and accountability.'
            },
            {
                imageSrc: './wwh-group-1-image-2.png',
                imageAlt: 'Image 2',
                iconSrc: './wwh-group-1-icon-2.svg',
                iconAlt: 'Icon-2',
                title: 'Unified communication Hub',
                description: 'Leverage our in-app messaging platform for instant, reliable communication between shippers, carriers, and dispatchers, reducing response times and errors.'
            }
        ],
        imageBlock2: {
            src: "./wwh-group-1-image-3.png",
            alt: "./wwh-group-1-image-3.png",
            iconSrc: './wwh-group-1-icon-3.svg',
            iconAlt: 'Icon-3',
            title: "Intelligent data analytics",
            description: "Gain access to vital data and analytics through intuitive dashboards, empowering you to make informed, data-driven decisions for optimized operations."
        },
        imageBlock3: [
            {
                imageSrc: './wwh-group-1-image-4.png',
                imageAlt: 'Image-4',
                iconSrc: './wwh-group-1-icon-4.svg',
                iconAlt: 'Icon-4',
                title: 'User-Friendly Payment Features',
                description: 'Utilize Eusowallet, a secure virtual wallet, to facilitate quick and hassle-free transactions, improving cash flow and reducing administrative overhead.'
            },
            {
                imageSrc: './wwh-group-1-image-5.png',
                imageAlt: 'Image-5',
                iconSrc: './wwh-group-1-icon-5.svg',
                iconAlt: 'Icon-5',
                title: 'Esang AI: Your Virtual Hazmat Assistant',
                description: 'Esang AI offers real-time assistance with hazardous material classifications, job matching, fuel consumption analytics, and more, serving as your comprehensive digital guide in hazmat logistics.'
            }
        ],
        imageBlock4: [
            {
                imageSrc: './wwh-group-1-image-6.png',
                imageAlt: 'Image-4',
                iconSrc: './wwh-group-1-icon-6.svg',
                iconAlt: 'Icon-4',
                title: 'Reducing delays',
                description: 'EusoTrip’s Esang AI-driven real-time planning and route optimization reduce idle time, ensure compliance with HazMat restrictions, and save carriers up to 20% on fuel  costs while cutting transit times by up to 15%.'
            },
            {
                imageSrc: './wwh-group-1-image-7.png',
                imageAlt: 'Image-5',
                iconSrc: './wwh-group-1-icon-7.svg',
                iconAlt: 'Icon-5',
                title: 'Simplifying compliance',
                description: 'The platform automates documentation and compliance, reducing carriers admin burden and improving safety. It decreases delays by 30% and reduces fines by ensuring regulation compliance.'
            }
        ],
        imageBlock5: [
            {
                imageSrc: './wwh-group-1-image-8.png',
                imageAlt: 'Image-4',
                iconSrc: './wwh-group-1-icon-8.svg',
                iconAlt: 'Icon-4',
                title: 'Cost control',
                description: 'EusoTrip’s transparent pricing and efficient route planning boost earnings, cut fuel use, and reduce expenses, increasing carriers profitability by up to 10%.'
            }
        ],
        content1: 'By addressing these challenges, EusoTrip not only enhances individual efficiencies but also creates a connected HazMat logistics ecosystem, enabling suppliers, dispatchers, and brokers to collaborate seamlessly on one secure platform. This comprehensive approach positions EusoTrip as a transformative force in the HazMat logistics market, driving significant improvements in safety, efficiency, and profitability.'
    },
    exploreSection: {
        title: "Explore more about your role"
    },
    forShipper: {
        subscript: "For",
        title: "Shipper",
        description: "Supplier | Marketer | Broker",
        image: {
            src: './for-shipper.png',
            alt: 'for-shipper'
        }
    },
    forCarrier: {
        subscript: "For",
        title: "Carrier",
        description: "Dispatcher  | Company Carrier | Individual Carrier",
        image: {
            src: './for-carrier.png',
            alt: 'for-carrier'
        }
    },
    forPilot: {
        subscript: "For",
        title: "Pilot",
        description: "Pilot Catalyst | Company Catalyst | Pilot Dispatcher ",
        image: {
            src: './for-pilot.png',
            alt: 'for-pilot'
        }
    }
}

export const carrier = {
    title: 'Carrier',
    subTitle: 'Dispatcher  | Company Carrier | Individual Carrier',
    header: {
        content: 'EusoTrip empowers carriers with efficient transportation solutions. Easily manage drivers, access a marketplace of shipper jobs,  and bid on contracts. Stay connected with real-time tracking and instant payments via EusoWallet. Experience the future of transportation logistics  with EusoTripEusoTrip empowers carriers with efficient transportation solutions. Easily manage drivers, access a marketplace of shipper jobs, and bid on contracts. Stay connected with real-time tracking and instant payments via EusoWallet. Experience the future of transportation logistics with EusoTrip.'
    },
    challenges: {
        title: 'Challenges',
        columns: [
            {
                title: 'Catalysts (Carriers)',
                subTitle: 'Inefficiencies and Revenue Loss',
                content: 'Wasted Time on Load Matching: Manually searching for HazMat loads across multiple platforms and channels eats into carriers earning potential. The lack of a centralized, real-time marketplace leads to empty miles, missed opportunities, and idling trucks. On average, 20-30% of all truck miles are driven empty, significantly impacting revenue opportunities​ (Inbound Logistics)​​ (Export and Freight)​. This issue is compounded by the ever-changing regulatory landscape, forcing carriers to expend time and resources reconfirming route compliance with each new shipment type.\n\nSuboptimal Routes: HazMat route restrictions require specialized expertise. Carriers often rely on generic route planners designed for standard cargo, resulting in 15-20% longer routes, increased fuel costs, and the potential for non-compliance – all negatively impacting earnings​ (Fleet Point)​.\n\nDelayed Payments: Opaque pricing structures and lengthy invoicing processes can lead to cash flow issues. Around 40% of carriers report experiencing payment delays, which hampers their ability to operate, invest in their fleet, and retain drivers​ (Inbound Logistics)​​ (Fleet Point)​.'
            },
            {
                title: 'Dispatchers',
                subTitle: 'Operational Complexity and Bottlenecks',
                content: 'Manual Load Management: Juggling carrier communication, route planning, and HazMat documentation with outdated tools limits dispatchers productivity. Inefficiencies result in delayed shipments and missed opportunities, impacting the carriers bottom line. Approximately 70% of dispatchers rely on manual processes, which are prone to errors and inefficiencies​ (Fleet Point)​.\n\nInformation Silos: Lack of a unified platform leads to data fragmentation across spreadsheets and phone calls. This makes it difficult to optimize routes, match the right loads to carriers, and ultimately maximize earning potential across the dispatch network. Around 55% of dispatchers struggle with fragmented data management​ (Inbound Logistics)​​ (Fleet Point)​.\n\nCompliance Headaches: Ensuring drivers have current certifications and that vehicles meet HazMat standards adds a layer of administrative complexity. Any missteps in compliance can lead to costly fines, averaging $50,000 per violation, and impact the carriers ability to continue servicing loads​ (Inbound Logistics)​​ (Fleet Point)​.'
            }

        ]
    },
    keyFeatures: {
        info: {
            image1: {
                src: './carrier-ifo-image-1.png',
                alt: 'Image 1'
            },
            image2: {
                src: './carrier-ifo-image-2.png',
                alt: 'Image 2'
            },
            iconSrc: './carrier-icon-1.svg',
            iconAlt: 'Icon-1',
            title: 'Real-time Load Matching Engine',
            description: 'Benefit for Carriers: Quickly find qualified HazMat jobs, cutting load matching time by up to 50%. AI-powered matching boosts productivity and revenue by considering route and driver specifics, reducing empty miles.'
        },
        imageBlock0: [
            {
                imageSrc: './carrier-image-3.png',
                imageAlt: 'Image 3',
                iconSrc: './carrier-icon-2.svg',
                iconAlt: 'Icon-2',
                title: 'Fleet management',
                description: 'Easily manage your fleet inventory by adding, editing, and updating details as needed. Keep track of your vehicles, trailers, and equipment, ensuring accurate and up-to-date information at all times.'
            },
            {
                imageSrc: './carrier-image-4.png',
                imageAlt: 'Image 4',
                iconSrc: './carrier-icon-3.svg',
                iconAlt: 'Icon-3',
                title: 'Driver management',
                description: 'Seamlessly manage your drivers within the platform. Add new drivers, edit existing profiles, and keep essential information up to date, ensuring smooth operations and compliance with regulations.'
            }
        ],
        imageBlock: [
            {
                imageSrc: './carrier-image-5.png',
                imageAlt: 'Image 5',
                iconSrc: './carrier-icon-4.svg',
                iconAlt: 'Icon-4',
                title: 'Real-time monitoring',
                description: 'Stay informed with real-time monitoring of loading and unloading activities. Track the timing of material loading, providing valuable insights into job progress and ensuring timely deliveries.'
            },
            {
                imageSrc: './carrier-image-6.png',
                imageAlt: 'Image 6',
                iconSrc: './carrier-icon-5.svg',
                iconAlt: 'Icon-5',
                title: 'Instant verification',
                description: 'Instantly verify deliveries by capturing electronic signatures. This ensures proof of receipt and enhances accountability in the delivery process.'
            }
        ],
        technologySection: {
            image1: {
                src: './carrier-image-7.png',
                alt: 'Image 1'
            },
            image2: {
                src: './carrier-image-8.png',
                alt: 'Image 2'
            },
            image3: {
                src: './carrier-image-9.png',
                alt: 'Image 2'
            },
            mobileImg: {
                src: './technolgy-mobile.png',
                alt: 'Image 4'                
            }
        }
    }
}

export const shipper = {
    title: 'Shipper',
    subTitle: 'Supplier | Marketer | Broker',
    header: {
        content: 'Our platform transforms the logistics landscape, offering a seamless and efficient way to transport goods. Whether you are a small business or a large enterprise, EusoTrip connects you to a vast network of trusted carriers and state-of-the-art technology, optimizing your supply chain. EusoTrip simplifies the shipping process with transparent pricing, real-time tracking, and effortless communication. By harnessing our technology, shippers can effortlessly book, track, and manage shipments, saving time and cutting costs. Join the community of shippers who rely on EusoTrip for safe and efficient delivery. Discover the future of freight logistics with EusoTrip – where shipping meets simplicity.'
    },
    challenges: {
        title: 'Challenges',
        columns: [
            {
                title: 'Suppliers',
                subTitle: 'Uncertainty and Compliance Risks',
                content: 'Unreliable Carriers: Inconsistent carrier availability and a lack of pre-vetting for HazMat transport lead to delays, missed deadlines, and potential supply chain disruptions. These issues can result in revenue losses or costly penalties, with approximately 30% of shipments experiencing delays due to carrier-related problems​ (Inbound Logistics)​​ (The Logistics Point)​.\n\nCompliance Worries: The burden of verifying carrier credentials and ensuring regulatory compliance rests on the supplier. Errors can result in fines averaging $50,000 per incident, penalties, supply chain holdups, and costly reputational damage, ultimately impacting future earnings​ (The Logistics Point)​.\n\nLimited Visibility: Lack of real-time tracking capabilities for HazMat shipments leaves suppliers unable to proactively address delays. This affects downstream processes and can ultimately lead to revenue loss, with around 50% of suppliers reporting limited visibility into their shipments​ (Fleet Point)​​ (The Logistics Point)​.'
            },
            {
                title: 'Brokers',
                subTitle: 'Market Volatility and Lack of Transparency',
                content: 'Finding Reliable Carriers: Sourcing vetted, available HazMat carriers is time-consuming. Brokers often rely on a fragmented network of carriers and outdated contact lists, which eats into time that could be spent securing profitable loads. This results in 25-30% of loads being delayed or unfulfilled due to carrier shortages​ (Fleet Point)​​ (The Logistics Point)​.\n\nOpaque Pricing: The lack of standardized pricing and real-time market data makes it difficult for brokers to offer competitive rates, secure profitable loads, and maximize their earnings. Approximately 60% of brokers report difficulties in maintaining competitive pricing due to lack of transparency​ (Inbound Logistics)​​ (Export and Freight)​.\n\nRegulatory Uncertainty: Verifying compliance and keeping track of changing regulations adds a layer of operational complexity. If not managed carefully, this can lead to delays in securing loads, or even fines impacting the brokers profitability. Around 50% of brokers find it challenging to stay updated with regulatory changes​ (Fleet Point)​​ (The Logistics Point)​.'
            }

        ]
    },
    keyFeatures: {
        imageBlock0: [
            {
                imageSrc: './shipper-image-1.png',
                imageAlt: 'Image 1',
                iconSrc: './shipper-icon-1.svg',
                iconAlt: 'Icon 1',
                title: 'Easy shipment creating',
                description: ' Effortlessly create shipments by providing all necessary information in one intuitive interface.'
            },
            {
                imageSrc: './shipper-image-2.png',
                imageAlt: 'Image 2',
                iconSrc: './shipper-icon-2.svg',
                iconAlt: 'Icon-2',
                title: 'Load management',
                description: 'Seamlessly manage all your loads in one place. From pending to scheduled and in-progress shipments, easily keep track of their status.'
            }
        ],
        imageBlock: [
            {
                imageSrc: './carrier-image-1.png',
                imageAlt: 'Image 3',
                iconSrc: './bo-icon-15.svg',
                iconAlt: 'Icon-2',
                title: 'Warehouse address',
                description: 'Streamlined Shipment Creation Simplify the shipment creation process by utilizing your warehouse addresses from  "My Facility Book." No need to enter address details manually each time; simply select the warehouse address you have added, saving time and reducing errors.'
            },
            {
                imageSrc: './carrier-image-2.png',
                imageAlt: 'Image 4',
                iconSrc: './shipper-icon-4.svg',
                iconAlt: 'Icon-4',
                title: 'Efficient cargo handling',
                description: 'Manage large volumes of materials with ease. By visualizing multiple filled trailers, you can effectively plan and coordinate the transportation of your cargo, optimizing efficiency and minimizing delays.'
            }
        ],
        // imageBlock1: [
        //     {
        //         imageSrc: './shipper-image-5.png',
        //         imageAlt: 'Image 5',
        //         iconSrc: './shipper-icon-4.svg',
        //         iconAlt: 'Icon-4',
        //         title: 'WAREHOUSE ADDRESS',
        //         description: 'Streamlined Shipment Creation Simplify the shipment creation process by utilizing your warehouse addresses from  "My Facility Book." No need to enter address details manually each time; simply select the warehouse address you have added, saving time and reducing errors.'
        //     },
        //     {
        //         imageSrc: './shipper-image-6.png',
        //         imageAlt: 'Image 6',
        //         iconSrc: './shipper-icon-5.svg',
        //         iconAlt: 'Icon-5',
        //         title: 'EFFICIENT CARGO HANDLING',
        //         description: 'Manage large volumes of materials with ease. By visualizing multiple filled trailers, you can effectively plan and coordinate the transportation of your cargo, optimizing efficiency and minimizing delays.'
        //     }
        // ],
        info: {
            image1:{
                src: './shipper-image-5.png',
                alt: 'Image 5'
            },
            image2:{
                src: './shipper-image-6.png',
                alt: 'Image 6'
            },
            iconSrc: './shipper-icon-5.svg',
            iconAlt: 'Icon-5',
            title: 'Bid request management',
            description: 'Choose the bid that best suits your requirements. Whether you opt to accept a bid or negotiate by making a counteroffer, enjoy the flexibility to make decisions that align with your shipping priorities.'
          },
        technologySection: {
            image1: {
                src: './carrier-image-7.png',
                alt: 'Image 1'
            },
            image2: {
                src: './carrier-image-8.png',
                alt: 'Image 2'
            },
            image3: {
                src: './carrier-image-9.png',
                alt: 'Image 3'
            },
            mobileImg: {
                src: './technolgy-mobile.png',
                alt: 'Image 4'                
            }
        }
    }
}

export const esang = {
    title: 'Esang',
    subTitle: 'Esang AI: The Heart of Optimization',
    header: {
        content: 'EusoTrip leverages a suite of cutting-edge technologies to revolutionize the HazMat logistics industry. This technological edge ensures a seamless, efficient, and safe experience for all stakeholder.'
    },
    contentBlock1: [
        {
            title: 'Benefit (All Users)',
            content: 'Specialized routing algorithms powered by Esang AI factor in real-time data and the latest HazMat regulations, ensuring safe, compliant, and fuel-efficient routes. This minimizes delays, fines, fuel costs, and maximizes earnings for carriers. Optimized routing can cut fuel costs by up to 15% and reduce transit times by 10-15%​ (Fleet Point)​​ (The Logistics Point).'
        },
        {
            title: 'Core Innovation',
            content: 'At its core, EusoTrip features Esang AI, a proprietary artificial intelligence engine, meticulously crafted to navigate the complexities of HazMat logistics. This advanced AI is pivotal in transforming logistics operations by offering more than just route optimization.'
        },
        {
            title: 'Comprehensive Data Analysis',
            content: 'Esang AI processes extensive real-time and historical data encompassing route restrictions, traffic patterns, carrier capabilities, and evolving HazMat regulations. This enables a holistic approach to logistics management.'
        },
    ],
    contentBlock2: [
        {
            title: 'Intelligent Load Matching',
            content: 'Beyond simple compatibility, it strategically matches carriers with loads to maximize logistical efficiency and minimize empty miles.'
        },
        {
            title: 'Compliance and Delay Avoidance',
            content: 'With a proactive stance, Esang AI foresees potential delays and compliance risks, facilitating preemptive measures to mitigate these issues effectively.'
        },
        {
            title: 'Dynamic Route Optimization',
            content: 'Esang AI ensures the selection of the most efficient routes by continuously assessing current road conditions and legal restrictions.'
        },
    ],
    imageBlock: [
        {
            imageSrc: './shipper-image-3.png',
            imageAlt: 'Image 3',
        },
        {
            imageSrc: './shipper-image-4.png',
            imageAlt: 'Image 4',
        }
    ],
    content1: 'Esang AI ensures the selection of the most efficient routes by continuously assessing current road conditions and legal restrictions.',
    content2: 'By leveraging advanced technologies like Esang AI, real-time data integration, and robust security measures, EusoTrip provides a comprehensive, efficient, and secure solution for HazMat logistics. These innovations not only streamline operations but also significantly enhance safety and compliance, positioning EusoTrip as a leader in the HazMat logistics industry.',
    esangDesignBlock: {
        header: 'Esang System Design'
    }
}

export const bo = {
    title: 'Business Overview',
    subTitle: 'Vision',
    imageSrcArrow: './bo-vision-arrow.png',
    visionCaption: 'Revolutionizing Hazmat Logistics: Safer, Smarter, Compliant.',
    header: {
        content: 'The global hazardous materials (HazMat) logistics market is a substantial and growing sector, currently valued at approximately $230.2 billion in 2022. This market is projected to grow significantly, reaching $311.27 billion by 2028 at a compound annual growth rate (CAGR) of 7.4%​ (ResearchHub)​​ (BlueWeave Consulting)​. The U.S. market alone represents a significant portion, estimated to be around $86.5 billion annually. This trajectory underscores the significant scale and ongoing expansion of HazMat transportation, driven by increasing regulatory requirements and the rising complexity of supply chains.'
    },
    marketoppotunities: {
        title: 'Market Opportunities',
        text: 'Unlocking a Multi-Billion Dollar Opportunity',
        imageSrc: './bo-market-oportunities-image.png',
        imageAlt: './bo-market-oportunities-image.png',
        contentTitle: 'EusoTrip and Market Segmentation',
        contentText: 'EusoTrip strategically positions itself within the HazMat logistics landscape, catering to high-demand segments that involve the transportation of flammable liquids (e.g., gasoline, crude oil, jet fuel, ethanol), toxic solids (e.g., pesticides, arsenic compounds), and corrosive substances (e.g., sulfuric acid, hydrochloric acid). These materials represent over 60% of the U.S. HazMat transportation market (Export and Freight), driven by key industries such as oil and gas, chemicals, and pharmaceuticals. By focusing on these critical sectors, EusoTrip can effectively address the growing need for specialized HazMat transportation solutions, ensuring compliance, safety, and operational efficiency.',
        contentText1: ' The rise of e-commerce is another crucial driver transforming the HazMat logistics landscape. This sector is expected to witness a surge in demand for solutions that handle smaller-quantity HazMat shipments efficiently and compliantly. E-commerce sales are projected to grow at a CAGR of 14.7%, significantly impacting the demand for specialized logistics solutions​ (Export and Freight)​. EusoTrips platform is designed to address these specific needs within the e-commerce ecosystem, providing a competitive edge in a rapidly evolving market.'
    },
    painPoints: {
        title: 'Turning Industry Challenges into Opportunities',
        contentText1: 'The HazMat logistics industry, despite its size and essential role in global commerce, faces persistent inefficiencies. Manual processes, fragmented operations, and extended payment cycles result in underutilized trucking capacities and lack transparency due to intermediary practices. These challenges drive up operational costs, increasing them by 15-20% through inefficiencies (Fleet Point).',
        contentText2: 'EusoTrip is poised to capitalize on these challenges, providing a technological solution that addresses the specific needs of both carriers and shippers. The platform enhances efficiency with real-time compliance automation, dynamic pricing, and AI-powered load matching, significantly reducing costs and streamlining operations.',
        contentText3: 'For carriers, EusoTrip optimizes load utilization, accelerates payments, and introduces operational transparency, enabling them to improve fleet performance and profitability. For shippers, the platform ensures real-time tracking, compliance with FMCSA regulations, and competitive pricing based on load type and volume, delivering reliable, cost-effective transport for hazardous materials.',
        contentText4: 'EusoTrip’s innovative approach transforms these challenges into opportunities, driving down operational costs while adding substantial value to the HazMat logistics ecosystem, positioning itself as a scalable, efficient, and transparent platform for the future.'
    },
    painPoints1: {
        title: 'Pain Points = Opportunity',
        content: 'Despite its size and potential, the HazMat logistics industry faces significant challenges. Manual and inefficient operations, extended payment cycles, underutilized trucking capacities, and a lack of transparency due to opaque practices by intermediaries plague the current system. These inefficiencies create a substantial, underserved market segment ripe for disruption through innovative technological solutions like EusoTrip. For example, inefficiencies in the current system can lead to a 15-20% increase in operational costs, presenting a significant opportunity for cost savings and efficiency improvements​ (Fleet Point)​.'
    },
    ecomandhazmar: {
        title: 'E-commerce and HazMat Logistics',
        contentText: 'E-commerce is emerging as a transformative force within the HazMat logistics sector, significantly impacting the demand for small-quantity hazardous material shipments. With the continued growth of online retail, more products containing regulated hazardous materials, such as batteries (flammable liquid electrolyte), nail polish (flammable liquid), and cleaning agents (corrosive substances), are being shipped directly to consumers. The growth in e-commerce, projected at a 14.7% compound annual growth rate (CAGR) (Export and Freight), is creating a surge in demand for logistics solutions capable of managing these frequent, small-scale HazMat shipments.',
        contentText1: 'EusoTrip is uniquely positioned to capitalize on this trend by offering a platform tailored to meet the specific requirements of HazMat shipping within the e-commerce ecosystem. The platform ensures that carriers can handle these smaller loads efficiently, while remaining fully compliant with safety and regulatory standards. As e-commerce continues to drive the need for specialized HazMat logistics, EusoTrip’s focus on compliance automation, dynamic pricing, and real-time tracking gives it a competitive edge in a rapidly evolving market.',
        contentText2: 'By addressing the needs of both large-scale industrial shipments and e-commerce-driven small-quantity HazMat transport, EusoTrip is poised to capture a significant share of the growing HazMat logistics market. The platform’s ability to cater to the unique demands of the e-commerce sector while maintaining compliance with FMCSA regulations and industry safety standards ensures its relevance in both traditional and emerging markets.'
    },
    regionalscaling: {
        title: 'Regional Scaling and Market Entry Strategy',
        contentText: 'EusoTrip’s regional scaling strategy focuses on entering and expanding within high-priority states based on industrial activity and driver availability. The platform will initially target regions such as Texas, Louisiana, and California, which have the largest pools of HazMat-certified drivers and high levels of industrial output. These states, with their significant oil, gas, and chemical sectors, are critical for HazMat transportation, making them ideal for early platform adoption.',
        contentText1: 'EusoTrip’s go-to-market strategy leverages dynamic pricing, compliance automation through the Carrier-OK API, and region-specific marketing to drive rapid adoption. By offering sliding-scale fees that incentivize larger carriers to scale operations, EusoTrip reduces the financial burden on carriers as they grow. This is particularly effective in states like Texas and Louisiana, where high volumes of hazardous materials are transported daily.',
        contentText2: 'The platform’s real-time compliance features, powered by the Carrier-OK API, ensure that carriers remain fully compliant with FMCSA regulations, minimizing the risk of costly violations. This compliance focus will be a key differentiator for carriers operating in high-risk environments, where safety and regulatory adherence are paramount.',
        contentText3: 'In addition to the initial focus on high-impact states, EusoTrip will scale regionally by leveraging strategic partnerships with local industry associations and transportation bodies. These partnerships will drive visibility and trust within the target markets, helping to accelerate platform adoption and solidify EusoTrip as the go-to solution for HazMat logistics.'
    },
    scalingmarkets: {
        title: 'Regional Scaling and Market Entry Strategy',
        contentText: 'Once EusoTrip establishes a strong foothold in its priority regions, the platform will expand into additional states like Illinois, Ohio, Pennsylvania, and New Jersey, which also boast large concentrations of HazMat-certified drivers. This expansion will be driven by a combination of localized marketing campaigns, tailored to each state’s specific industrial needs, and referral programs to encourage existing users to bring new carriers onto the platform.',
        contentText1: 'EusoTrip’s ability to scale rapidly is enhanced by its digital infrastructure, allowing it to efficiently onboard new carriers in regions with dense industrial activity. By offering automated compliance tools, dynamic pricing, and region-specific support, EusoTrip is uniquely positioned to become the leading platform in the HazMat logistics sector across multiple regions.',
    },
    contentBlock1: {
        title: 'Our Differentiators',
        description: "EusoTrip sets itself apart through several key factors",
        blocks: [
            {
                title: 'Specialized Focus on HazMat Logistics',
                iconSrc: './bo/bo-icon-2.svg',
                iconAlt: 'Icon 1',
                content: 'EusoTrip is built exclusively for HazMat logistics, addressing the unique regulatory, safety, and operational demands of hazardous materials. This sector-specific expertise ensures full compliance and optimized handling for high-risk shipments—differentiating us from general logistics platforms.'
            },
            {
                title: 'Comprehensive user ecosystem',
                iconSrc: './bo/bo-icon-6.svg',
                iconAlt: 'Icon 1',
                content: 'EusoTrip serves carriers, shippers, dispatchers, and Pilot Drivers in a unified platform, enhancing collaboration and real-time communication. This inclusive ecosystem improves efficiency across the entire HazMat supply chain, providing visibility and coordination for all users.'
            },
            {
                title: 'Enhanced Safety and Risk Mitigation',
                iconSrc: './bo/bo-icon-1.svg',
                iconAlt: 'Icon 3',
                content: 'EusoTrip’s platform includes real-time safety alerts, risk assessments, and compliance reminders, such as driver working hours and truck inspection schedules. Integrated weather updates and facility amenities alerts allow drivers to make well-informed, safe decisions throughout their journeys. These safety-focused features are crucial for preventing incidents and maintaining regulatory compliance in the high-stakes HazMat environment.'
            },
            {
                title: 'Advanced Technology Integration',
                iconSrc: './bo/bo-icon-4.svg',
                iconAlt: 'Icon 3',
                content: 'With Esang AI for predictive load matching and the Carrier-OK API for real-time compliance, EusoTrip automates operations, reduces compliance risks, and optimizes routes. This technology-driven approach enables proactive logistics management, reducing costs and errors.'
            },
            {
                title: 'Dynamic Pricing for Cost Efficiency',
                iconSrc: './bo/bo-icon-5.svg',
                iconAlt: 'Icon 3',
                content: 'EusoTrip’s sliding-scale fee model adapts to carrier volume and load type, offering scalable, cost-effective pricing. This flexible structure supports both small and large carriers, aligning pricing with operational needs and enhancing profitability.'
            },
            {
                title: 'Real-Time Compliance Assurance',
                iconSrc: './bo/bo-icon-3.svg',
                iconAlt: 'Icon 3',
                content: 'Automated compliance checks through the Carrier-OK API provide continuous regulatory adherence for every shipment. This real-time assurance reduces administrative burdens and safeguards against costly violations, essential in the high-stakes HazMat environment.'
            },
        ]
    },
    contentBlock2: {
        title: 'Barriers to Entry',
        description: "Several factors create barriers to entry for new competitors in this space",
        blocks: [
            {
                title: 'Industry data access',
                iconSrc: './bo-icon-4.svg',
                iconAlt: 'Icon 4',
                content: 'EusoTrips deep understanding of the HazMat logistics industry, coupled with access to crucial data, positions it strategically'
            },
            {
                title: 'Technological infrastructure',
                iconSrc: './bo-icon-5.svg',
                iconAlt: 'Icon 5',
                content: 'The advanced technological foundation of EusoTrip creates a significant hurdle for new entrants to replicate.'
            },
            {
                title: 'Network effect',
                iconSrc: './bo-icon-6.svg',
                iconAlt: 'Icon 6',
                content: 'EusoTrips established user base benefits from a network effect, creating further barriers for new players attempting to gain traction.'
            },
        ]
    },
    contentBlock4: {
        title: 'Scalability',
        description: "EusoTrip's technology-driven model prioritizes scalability, enabling growth without proportional cost increases",
        blocks: [
            {
                title: 'Cloud-based infrastructure',
                iconSrc: './bo-icon-12.svg',
                iconAlt: 'Icon 12',
                content: 'The use of cloud-based solutions provides flexibility and adaptability in resources. This allows EusoTrip to efficiently scale its computing power, data storage, and network capabilities in line with increased user demand and operational growth.'
            },
            {
                title: 'Automated systems',
                iconSrc: './bo-icon-13.svg',
                iconAlt: 'Icon 13',
                content: ' Extensive automation of processes like load matching, compliance checks, and documentation reduces the need for manual interventions and additional operational overhead as the platform expands.'
            },
            {
                title: 'Data-driven optimization',
                iconSrc: './bo-icon-14.svg',
                iconAlt: 'Icon 14',
                content: 'Machine learning algorithms and data analytics continuously optimize route planning, load matching, and other key processes. This proactive optimization helps to accommodate expanding volumes without sacrificing efficiency.'
            },
        ]
    },
    longTermVision: {
        title: 'Long-term Vision',
        content: [
            {
                title: 'Bulk material transport',
                iconSrc: './bo-icon-7.svg',
                iconAlt: 'Icon 7',
                content: 'EusoTrip could leverage its core competencies to optimize the transportation of non-hazardous bulk materials like construction materials, agricultural products, and more. This expansion opens potential new revenue streams while maintaining a specialized focus.',
            },
            {
                title: 'Comprehensive logistics solutions',
                iconSrc: './bo-icon-8.svg',
                iconAlt: 'Icon 8',
                content: 'Building on its platforms capabilities, EusoTrip could evolve into a comprehensive logistics solution provider, addressing diverse shipping needs and further increasing its overall value proposition within the industry.',
            },
        ],
        imageBlock: {
            src: './bo-long-term-vision-image.png',
            alt: 'Long Term Vision'
        }
    },
    contentBlock3: {
        title: 'Key Points for Investors',
        description: 'By providing comprehensive, data-driven insights and leveraging cutting-edge technology, EusoTrip is well-positioned to capitalize on the growing HazMat logistics market, offering significant returns for investors and stakeholders alike',
        blocks: [
            {
                title: 'Cost-efficient growth',
                iconSrc: './bo-icon-9.svg',
                iconAlt: 'Icon 9',
                content: 'EusoTrips scalability model presents a significant advantage for investors. Expansion translates into increased revenue potential without a proportionally linear increase in operational costs.'
            },
            {
                title: 'Adaptability',
                iconSrc: './bo-icon-10.svg',
                iconAlt: 'Icon 10',
                content: 'The platforms flexible foundation enables quick adaptation to changing market dynamics, evolving regulations, and future growth opportunities in new segments.'
            },
            {
                title: 'Ambitious vision',
                iconSrc: './bo-icon-11.svg',
                iconAlt: 'Icon 11',
                content: 'EusoTrips long-term goal to expand its service offerings demonstrates its dedication to continuous innovation and market expansion, solidifying its potential as a long-term value generator.'
            },
        ]
    },
    competitiveAnalysis: {
        title: 'Competitive Analysis',
        text: 'The HazMat logistics landscape currently features various players, each with their strengths and weaknesses. Heres a breakdown of the key competitor types EusoTrip aims to surpass',
    },
    businessModel: {
        title: 'Business Model',
        description: 'Revenue road map',
        imageSrc: './bo-business-model-image.png',
        imageSrc1: './bo-business-model-bg.png',
        imageAlt1: 'bo-business-model-bg',
        imageAlt: 'Business Model',
    },
    eusotripAdvantage: {
        title: 'EusoTrip Advantage',
        imageSrc: './bo-eusotrip-advantage-image.png',
        imageAlt: 'Eusotrip Advantage',
    },
    financialProjection: {
        title: 'Financial Projections',
        content1: 'For a comprehensive overview of the financial projections data, including projections, calculations, and detailed breakdowns, please refer to the Excel sheet linked below. This document provides all relevant insights and data points critical to understanding the financial aspects of this project.',
        content: 'For a detailed and comprehensive understanding of EusoTrip’s financial outlook, please refer to the accompanying Financial Model (link), which contains projections, key calculations, and financial breakdowns. This document provides an in-depth analysis of revenue growth, operating expenses, and profitability over the coming years. These projections are built on realistic market assumptions and are critical for assessing the financial viability and growth potential of the platform.',
        content1: 'Our partners can explore these projections to gain a clear understanding of the financial strategy driving EusoTrip’s growth and how we plan to capture market share in the HazMat logistics sector.',
        imageBlock: [
            {
                imageSrc: './bo-fina-projection-image-1.png',
                imageAlt: 'Image 1',
            },
            {
                title: "Operating Expenses",
                imageSrc: './bo-fina-projection-icon-1.svg',
                imageAlt: 'bo-fina-icon-1',
                content : [
                    {
                    key: "Marketing & Customer Acquisition:",
                    value: "$1M/year",
                },
                {
                    key: "Technology & Development:",
                    value: "$800K/year",
                },
                {
                    key: "Operations & Support:",
                    value: "$400K/year",
                },
                {
                    key: "Miscellaneous:",
                    value: "$200K/year",
                }
            ]
            }
        ],
        imageBlock1: [
            {
                imageSrc: './bo-fina-projection-image-2.png',
                imageAlt: 'Image 2',
            },
            {
                title: "Profitability Estimates",
                imageSrc: './bo-fina-projection-icon-2.svg',
                imageAlt: 'bo-fina-icon-2',
                content : [
                    {
                    key: "Tiered Subscription Model",
                    value: "Introducing in Year 2 to diversify revenue streams.",
                },
                {
                    key: "Strategic Partnerships",
                    value: "Collaborating with industry leaders for mutual growth.",
                },
                {
                    key: "Aggressive User Acquisition",
                    value: "Focusing on transaction fees in the first two years to build a strong user base.",
                }
            ]
            }

        ],
        imageBlock2: [
            {
                imageSrc: './bo-fina-projection-image-3.png',
                imageAlt: 'bo-fina-projection-image-3',
            }
        ],
        imageBlock3: [
            {
                imageSrc: './summary-table.png',
                imageAlt: 'Image 5',
            }
        ],
        keyInsights : [
            "Revenue growth trajectory based on platform adoption rates",
            "Cost structures and how our dynamic pricing and scaling model reduce operational expenses over time.",
            "Projected profitability as we scale across priority regions and onboard more carriers and shippers."
        ]
    },
    milestones : {
        title: 'Milestones',
        imageSrc: '../bo/milestone.png',
        imageSrcMobile: '../bo/milestone-mobile.png',
        imageAlt: 'Image 6',
    }

}